import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import { I18n } from '@lingui/react'
import {
  Container,
  MainText,
  PrimaryTitle,
  SecondaryTitle,
} from 'styles/Global'
import {
  LegalsContent,
  LegalsImage,
  LegalsParagraph,
  LegalsSection,
} from 'styles/pages/legals.style'
import { fetchLegalsImg } from 'hooks/fetch-legals-img'

import Layout from 'components/Layout'
import Image from 'components/Image'
import SEO from 'components/Seo'
import Footer from 'components/molecules/Footer/Footer'
import Header from 'components/organisms/Header/Header'
import sanitize from 'sanitize-html'

interface Props {}

const LegalsPage: FunctionComponent<Props> = () => {
  const file = fetchLegalsImg()

  return (
    <Layout>
      <I18n>
        {({ i18n }) => (
          <SEO title={i18n._("legals.metaTitle")} />
        )}
      </I18n>
      <Header />
      <LegalsSection>
        <LegalsImage>
          <Image fluid={file.fluid} alt={file.alt} />
        </LegalsImage>
        <Container>
          <LegalsContent>
            <PrimaryTitle>
              <Trans id="legals.title">Mentions légales</Trans>
            </PrimaryTitle>
            <I18n>
              {({ i18n }) => (
                <LegalsParagraph>
                  <MainText>
                    <Trans id="legals.paragraph1.text.1">
                      Redfabriq, au capital social de 1 855 000 euros, inscrite
                      au registre du commerce et des sociétés de Paris sous le
                      numéro Siren 501 705 172, est le responsable du traitement
                      des données collectées sur le Site de Redfabriq.
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="legals.paragraph1.text.2">
                      L’utilisateur est notamment informé que conformément à
                      l’article 32 de la loi Informatique et libertés du 6
                      janvier 1978 modifiée, les informations qu’il communique
                      par les formulaires présents sur le Site de Redfabriq sont
                      nécessaires pour répondre à sa demande et sont destinées
                      aux services en charge de répondre à sa demande à des fins
                      de suivi de cette demande.
                    </Trans>
                  </MainText>
                  <MainText
                    dangerouslySetInnerHTML={{
                      __html: sanitize(i18n._('legals.paragraph1.text.3')),
                    }}
                  />
                  <MainText>
                    <Trans id="legals.paragraph1.text.4">
                      Vous bénéficiez également du droit de donner des
                      directives sur le sort de vos données après votre décès.
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="legals.paragraph1.text.5">
                      Conformément aux dispositions de l’article 38 de la loi «
                      Informatique et Libertés » du 6 janvier 1978 modifiée en
                      2004, l’utilisateur peut également s’opposer, pour des
                      motifs légitimes à ce que ses données fassent l’objet d’un
                      traitement et sans motif et sans frais, à ce que ses
                      données soient utilisées à des fins de prospection
                      commerciale.
                    </Trans>
                  </MainText>
                  <MainText
                    dangerouslySetInnerHTML={{
                      __html: sanitize(i18n._('legals.paragraph1.text.6')),
                    }}
                  />
                  <MainText>
                    <Trans id="legals.paragraph1.text.7">
                      Les informations recueillies sur ce site seront conservées
                      pendant une durée de trois ans.
                    </Trans>
                  </MainText>
                  <MainText
                    dangerouslySetInnerHTML={{
                      __html: sanitize(i18n._('legals.paragraph1.text.8')),
                    }}
                  />
                  <MainText>
                    <Trans id="legals.paragraph1.text.9">
                      L’utilisateur dispose d’un droit d’accès, de retrait et de
                      modification des données à caractère personnel
                      communiquées par le biais des cookies dans les conditions
                      indiquées ci-dessus.
                    </Trans>
                  </MainText>
                  <MainText>
                    <Trans id="legals.paragraph1.text.10">
                      L’utilisateur du Site de Redfabriq est tenu de respecter
                      les dispositions de la loi Informatique et libertés du 6
                      janvier 1978 modifiée dont la violation est passible de
                      sanctions pénales. Il doit notamment s’abstenir,
                      s’agissant des informations nominatives auxquelles il
                      accède, de toute collecte, de toute utilisation détournée,
                      et d’une manière générale, de tout acte susceptible de
                      porter atteinte à la vie privée ou à la réputation des
                      personnes.
                    </Trans>
                  </MainText>
                </LegalsParagraph>
              )}
            </I18n>
            <LegalsParagraph>
              <SecondaryTitle>
                <Trans id="legals.paragraph2.title">
                  Partage avec des tiers et transfert vers les pays tiers
                </Trans>
              </SecondaryTitle>
              <MainText>
                <Trans id="legals.paragraph2.text.1">
                  Nous pouvons partager des données personnelles avec des tiers
                  aux fins expliquées ci-dessus. Pour éviter tout doute, nous ne
                  partagerons pas de données personnelles avec des tiers de
                  manière que ces tiers utilisent des données personnelles à des
                  fins de marketing direct en relation avec vous en tant que
                  particulier, mais uniquement avec vous en tant que
                  représentant de client actuel ou potentiel de Redfabriq.
                </Trans>
              </MainText>
              <MainText>
                <Trans id="legals.paragraph2.text.2">
                  Nous pouvons transférer des données personnelles dans un pays
                  en dehors de l’EEE, mais nous devrons alors tout d’abord
                  obtenir votre consentement ou veiller à ce que le transfert
                  soit légal et sécuritaire en prenant d’autres dispositions.
                </Trans>
              </MainText>
              <MainText>
                <Trans id="legals.paragraph2.text.3">
                  Comme expliqué ci-dessus, n’oubliez pas que cette charte de
                  confidentialité ne concerne aucun traitement de données pour
                  lequel vous pourriez être responsable à la suite de
                  l’utilisation de nos services. Vous, ou la partie que vous
                  représentez, êtes toujours responsable de votre traitement des
                  données personnelles conformément à la loi en vigueur.
                </Trans>
              </MainText>
            </LegalsParagraph>
            <LegalsParagraph>
              <SecondaryTitle>
                <Trans id="legals.paragraph3.title">
                  Conception, design, développement et administration du site
                  web
                </Trans>
              </SecondaryTitle>
              <MainText>
                <Trans id="legals.paragraph3.text.1">
                  Une production du design studio par Redfabriq.
                </Trans>
              </MainText>
              <MainText>
                <Trans id="legals.paragraph3.text.2">
                  Illustration : Alzea Arafat
                </Trans>
              </MainText>
              <MainText>
                <Trans id="legals.paragraph3.text.3">
                  Photographie : Orel Kichigai Photography
                </Trans>
              </MainText>
            </LegalsParagraph>
          </LegalsContent>
        </Container>
      </LegalsSection>
      <Footer />
    </Layout>
  )
}

export default LegalsPage
